import React from "react";
import { Route, Routes } from "react-router-dom";
import CompareRuns from "./pages/CompareRuns/CompareRuns";
import Benchmarks from "./pages/Benchmarks/Benchmarks";
import DemoSelection from "./pages/DemoSelection/DemoSelection";
import Login from "./pages/Login/Login";
import { Navigate } from "react-router-dom";

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const Router = () => (
  <Routes>
    <Route
      path="/"
      element={
        localStorage.getItem("token") ? (
          <Navigate to="/demo" />
        ) : (
          <Navigate to="/login" />
        )
      }
    />
    <Route path="/login" element={<Login />} />
    <Route path="/benchmarks" element={<Benchmarks />} />
    <Route path="/compare_runs:key" element={<CompareRuns />} />
    <Route path="/demo" element={<DemoSelection />} />
    <Route component={404} />
  </Routes>
);

export default Router;
