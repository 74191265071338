import React from "react";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHandHoldingDollar,
  faArrowTrendUp,
  faLeaf,
  faClock,
  faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import LinePlot from "../../components/LinePlot/LinePlot";
import {
  getListRouteData,
  getBenchmark,
  getOceanData,
} from "../../services/Benchmark_Backend";
import MapWrapper from "../../components/Map/MapWrapper";
import "./compareRuns.css";
import { isMobile } from "react-device-detect";
import GeoJSON from "geojson";
import Collapsible from 'react-collapsible';
import { BsChevronDown } from "react-icons/bs";
library.add(faHandHoldingDollar, faArrowTrendUp, faLeaf, faClock,faRepeat);

class CompareRuns extends React.Component {
  constructor() {
    super();
    //Init state
    this.smooth_rate_base = 0.3;
    this.state = {
      selected_runs: [],
      routes_data: [],
      compare_selected:[],
      waypointsIndices: [],
      smooth_rate: this.smooth_rate_base,
    };
    //retrieve benchmark id from url
    let queryParams = new URLSearchParams(window.location.search);
    this.benchmark_id = queryParams.get("key");

    this.routes_colors = [
      "#89c0d5",
      "#84ad3d",
      "#FCCB00",
      "#006B76",
      "#B80000",
      "#004DCF",
      "#EB9694",
      "#FEF3BD",
      "#BEDADC",
      "#BED3F3",
      "#D4C4FB",
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
      "#aec7e8",
      "#ffbb78",
      "#98df8a",
      "#ff9896",
      "#c5b0d5",
      "#c49c94",
      "#f7b6d2",
      "#c7c7c7",
      "#dbdb8d",
      "#9edae5",
    ];

    //Api calls and data init
    getBenchmark(this.benchmark_id).then((data) => {
      // Add a color to each run
      data.runs.forEach((run, index) => {
        run.color = this.routes_colors[index % this.routes_colors.length];
        run.run_name = run.run_name === "circumnavigate" ? "Shortest distance" : run.run_name;
        if(this.benchmark_id === "charleston_algeciras_sail_test")
          run.statistics.route_fuel *= 4;

      });
      this.setState({ runs: data.runs, benchmark: data });

      //fetch ocean data
      getOceanData(data.ocean_file.split("/").at(-1)).then((ocean) => {
        this.setState({ ocean: ocean });
      });
    });
  }

  circlesIndices(total_length, step) {
    let indices = [];

    for (let i = 0; i * step < total_length; i += 1) {
      indices.push(i * step);
    }

    //indices.push(total_length - 1);

    return indices;
  }

  get_longest(array){
    let longest = 0;
    array.forEach((d) => {
      if (d.timestamps.length > longest) {
        longest = d.timestamps.length;
      }
    });
    return longest;
  }

  addAlpha = (color, opacity) => {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  };

  downloadRoute = (file_name,route_data) => {
    var processed_file_name = file_name.replace(".", "_");
    var invertedCoords = route_data.coords.map((d) => [d[1], d[0]]);
    var geojsonData = GeoJSON.parse({"line":invertedCoords}, {'LineString': 'line'});
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(geojsonData));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", processed_file_name + ".geojson");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  green = "rgb(125,179,85)";
  red = "#DC143C";

  colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: this.addAlpha(data.color, 0.3),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
      filter: "brightness(0.65)",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      filter: "brightness(0.65)",
      ":hover": {
        backgroundColor: this.addAlpha(data.color, 0.3),
        color: "white",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  mul(array, n) {
    return array.map((d) => d * n);
  };

  add(array1, array2) {
    return array1.map((d, i) => d + array2[i]);
  };

  //add the route data from a run to the state
  async set_routes_data(runs) {
    //fetch route data for selected run files
    var data = await getListRouteData(      
      runs.map((run) => run.route_file.split("/").at(-1))
    );

    /*var new_data = data.map((d)=> {
      d.coords = this.smooth_route(d.coords,0);
      return d;
      }
    );*/
    this.setState({selected_runs: runs, routes_data: data, compare_selected:[runs[0],runs[1]] , waypointsIndices: data.map((d) => this.circlesIndices(d.timestamps.length, 10))});
  }

  fuel_diference = (route1,route2) => {
    return (
      route1.statistics.route_fuel - route2.statistics.route_fuel
    );
  };

  fuel_percentage = (route1,route2) => {
    return (
      (this.fuel_diference(route1,route2) / route1.statistics.route_fuel) * 100
    );
  };

  elapsed_time = (routes) => {
    let deltaSeconds = routes[1].statistics.duration * 3600;
    let days = Math.floor(deltaSeconds / 86400);
    deltaSeconds -= days * 86400;
    let hours = Math.floor(deltaSeconds / 3600);
    deltaSeconds -= hours * 3600;
    let minutes = Math.floor(deltaSeconds / 60);
    deltaSeconds -= minutes * 60;
    let seconds = Math.floor(deltaSeconds);
    return `${days} days, ${hours}:${minutes}:${seconds}`;
  };

  remove = (array, value) => {
    var arrayCopy = array.map((x) => x);
    const index = arrayCopy.indexOf(value);
    if (index > -1) { // only splice arrayCopy when item is found
      arrayCopy.splice(index, 1); // 2nd parameter means remove one item only
    }
    return arrayCopy;
  };

  render() {
    return (
      <>
        <Row
          className="justify-content-md-center mt-3"
          style={{ paddingBottom: "10px" }}
        >
          {this.state.runs ? (
            <>
              <h2 className="text-center blue-text">Select routes</h2>
              <Select
                className="mt-3"
                isMulti={true}
                options={this.state.runs.map((run, index) => {
                  return {
                    value: run,
                    label: run.run_name,
                    color: run.color,
                  };
                })}
                onChange={(e) => {
                  this.set_routes_data(e.map((i) => i.value));
                }}
                //isOptionDisabled={() => this.state.selected_runs.length >= 2}
                menuPortalTarget={document.body}
                styles={this.colourStyles}
              />

              {this.state.selected_runs.length > 2 ?
              (
                <Row
                  className="mt-3 flexbox-container"
                  style={{ paddingBottom: "10px" }}
                >        
                  <h4 className="blue-text" style={{marginTop:"15px",marginBottom:"15px"}}>Routes to compare</h4>
                  <Col xs="auto">
                  <h6 className="text-muted m-t-10 m-b-0">
                    Base route
                  </h6>
                  <Select
                    value={
                      {
                        value: this.state.compare_selected[0],
                        label:  this.state.compare_selected[0].run_name,
                        color:  this.state.compare_selected[0].color,
                      }
                    }
                    defaultValue={
                      {
                        value: this.state.compare_selected[0],
                        label:  this.state.compare_selected[0].run_name,
                        color:  this.state.compare_selected[0].color,
                      }
                    }
                    options={this.remove(this.state.selected_runs,this.state.compare_selected[1]).map((run, index) => {
                      return {
                        value: run,
                        label: run.run_name,
                        color: run.color,
                      };
                    })}
                    menuPortalTarget={document.body}
                    styles={this.colourStyles}
                    onChange={(e) => {
                      this.setState({compare_selected: [e.value, this.state.compare_selected[1]]});
                    }}
                  />
                  </Col>
                  <Col xs="auto" style={{marginTop:"1rem !important"}}>
                  <button
                    style={{
                    backgroundColor: "white",
                    color: "white",
                    borderWidth: "0px",
                    }}
                    onClick={() => {
                      this.setState({compare_selected: [this.state.compare_selected[1], this.state.compare_selected[0]]});
                    }}
                  >
                  <FontAwesomeIcon
                    icon="fa-solid fa-repeat"
                    fixedWidth
                    className={"blue-text"}
                  />
                  </button>
                  </Col>
                  <Col xs="auto" style={{marginTop:"1rem !important"}}>
                  <h6 className="text-muted m-t-10 m-b-0">
                    Compare route
                  </h6>
                  <Select
                    value={
                      {
                        value: this.state.compare_selected[1],
                        label:  this.state.compare_selected[1].run_name,
                        color:  this.state.compare_selected[1].color,
                      }
                    }
                    defaultValue={
                      {
                        value: this.state.compare_selected[1],
                        label:  this.state.compare_selected[1].run_name,
                        color:  this.state.compare_selected[1].color,
                      }
                    }
                    options={this.remove(this.state.selected_runs,this.state.compare_selected[0]).map((run, index) => {
                      return {
                        value: run,
                        label: run.run_name,
                        color: run.color,
                      };
                    })}
                    //isOptionDisabled={() => this.state.selected_runs.length >= 2}
                    menuPortalTarget={document.body}
                    styles={this.colourStyles}
                    onChange={(e) => {
                      this.setState({compare_selected: [this.state.compare_selected[0], e.value]});
                    }}
                  />
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {this.state.selected_runs.length >= 2 ? (
            <Row style={{ paddingTop: "20px" }}>
              <Col md={isMobile ? 6 : 3}>
                <Row>
                  <Col md="auto" style={{ fontSize: "50px" }}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-arrow-trend-up"
                      fixedWidth
                      className={"blue-text"}
                    />
                  </Col>
                  <Col className="align-self-center">
                    <h6 className="text-muted m-t-10 m-b-0">
                      Optimization rate
                    </h6>
                    <h2 className="m-t-0">
                      <b
                        style={{
                          color:
                            this.fuel_diference(this.state.compare_selected[0],this.state.compare_selected[1]) > 0
                              ? this.green
                              : this.red,
                        }}
                      >
                        {this.fuel_percentage(this.state.compare_selected[0],this.state.compare_selected[1]).toFixed(
                          2
                        )}{" "}
                        %
                      </b>
                    </h2>
                  </Col>
                </Row>
              </Col>

              <Col md={isMobile ? 6 : 3}>
                <Row>
                  <Col md="auto" style={{ fontSize: "50px" }}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-hand-holding-dollar"
                      fixedWidth
                      className={"blue-text"}
                    />
                  </Col>
                  <Col className="align-self-center">
                    <h6 className="text-muted m-t-10 m-b-0">Money Saved</h6>
                    <h2 className="m-t-0">
                      <b
                        style={{
                          color:
                            this.fuel_diference(this.state.compare_selected[0],this.state.compare_selected[1]) > 0
                              ? this.green
                              : this.red,
                        }}
                      >
                        {Math.round(
                          (this.fuel_diference(this.state.compare_selected[0],this.state.compare_selected[1]) *
                            1110) /
                          100
                        ) * 100}{" "}
                        $
                      </b>
                    </h2>
                  </Col>
                </Row>
              </Col>

              <Col md={isMobile ? 6 : 3}>
                <Row>
                  <Col md="auto" style={{ fontSize: "50px" }}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-leaf"
                      fixedWidth
                      className={"blue-text"}
                    />
                  </Col>
                  <Col className="align-self-center">
                    <h6 className="text-muted m-t-10 m-b-0">Saved emissions</h6>
                    <h2 className="m-t-0">
                      <b
                        style={{
                          color:
                            this.fuel_diference(this.state.compare_selected[0],this.state.compare_selected[1]) > 0
                              ? this.green
                              : this.red,
                        }}
                      >
                        {(
                          this.fuel_diference(this.state.compare_selected[0],this.state.compare_selected[1]) * 3
                        ).toFixed(2)}{" "}
                        CO<sub>2</sub> mt
                      </b>
                    </h2>
                  </Col>
                </Row>
              </Col>

              <Col md={isMobile ? 6 : 3}>
                <Row>
                  <Col md="auto" style={{ fontSize: "50px" }}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-clock"
                      fixedWidth
                      className={"blue-text"}
                    />
                  </Col>
                  <Col className="align-self-center">
                    <h6 className="text-muted m-t-10 m-b-0">Elapsed time</h6>
                    <h2 className="m-t-0">
                      <b style={{ color: "#1d2b59" }}>
                        {this.elapsed_time(this.state.selected_runs)}
                      </b>
                    </h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Row>
        <Collapsible
          triggerStyle={{
            width:"80px",
            color:"#374790",
          }} 
          trigger={["Settings", <BsChevronDown color="#374790" style={{padding:"2px"}}/>]} 
          containerElementProps={{className:"collapside-cell"}}
        >
          <div>
            <input type="checkbox" checked={this.state.smooth_rate !== 0} onChange={() => this.setState({smooth_rate:Math.abs(this.state.smooth_rate - this.smooth_rate_base)})} /> Smoothing routes
          </div>
        </Collapsible>
        {this.state.benchmark ? (
          <>
          <MapWrapper
            start_date={this.state.benchmark.start_date}
            routes={this.state.routes_data}
            city_start={this.state.benchmark.start_location_name}
            city_end={this.state.benchmark.end_location_name}
            start_coords={[
              this.state.benchmark.start_location_latitude,
              this.state.benchmark.start_location_longitude,
            ]}
            end_coords={[
              this.state.benchmark.end_location_latitude,
              this.state.benchmark.end_location_longitude,
            ]}
            routes_colors={this.state.selected_runs.map((i) => i.color)}
            circlesIndices={this.state.waypointsIndices}
            ocean={this.state.ocean}
            smooth_rate={this.state.smooth_rate}
          />
          </>
        ) : (
          ""
        )}

        {this.state.routes_data.length < 1 && !this.waypointsIndices ? (
          ""
        ) : (
          <Row className="justify-content-md-center mt-3">
            <Col>
              <LinePlot
                names={this.state.selected_runs.map((i) => i.run_name)} 
                timestamps={this.state.routes_data.map((i) => i.timestamps)}
                dataToPlot={this.state.routes_data.map(
                  (i) => i.weather.currents_contribution
                )}
                title={"Currents"}
                routes_colors={this.state.selected_runs.map((i) => i.color)}
                indicesSelectedPoints={this.state.waypointsIndices}
                movingAverage={false}
              />
            </Col>
            <Col>
              <LinePlot
                names={this.state.selected_runs.map((i) => i.run_name)} 
                timestamps={this.state.routes_data.map((i) => i.timestamps)}
                dataToPlot={this.state.routes_data.map(
                  (i) => i.weather.wind_contribution
                )}
                title={"Wind"}
                routes_colors={this.state.selected_runs.map((i) => i.color)}
                indicesSelectedPoints={this.state.waypointsIndices}
                movingAverage={false}
              />
            </Col>
            <Col>
              <LinePlot
                names={this.state.selected_runs.map((i) => i.run_name)} 
                timestamps={this.state.routes_data.map((i) => i.timestamps)}
                dataToPlot={this.state.routes_data.map((i) => i.weather.waves)}
                title={"Waves"}
                routes_colors={this.state.selected_runs.map((i) => i.color)}
                indicesSelectedPoints={this.state.waypointsIndices}
                movingAverage={false}
              />
            </Col>
          </Row>
        )}

        {this.state.selected_runs.length >= 1 ? (
          <div style={{ paddingTop: "20px" }}>
            <h5 className="text-center blue-text">Summary</h5>
            <Table className="mt-4 blue-text" bordered size="sm">
              <thead>
                <tr className="blue-text">
                  <th>Route</th>
                  <th>Distance</th>
                  <th>Time</th>
                  <th>Fuel consumption</th>
                  <th style={{width:"150px"}}>Download route</th>
                </tr>
              </thead>
              <tbody>
                {this.state.selected_runs.map((run, idx) => (      
                  <tr
                    key={idx}
                    style={{
                      backgroundColor: this.addAlpha(
                        run.color,
                        0.3
                      ),
                      color: run.color,
                    }}
                  >
                    <td className="summary-cell" style={{ filter: "brightness(0.65)" }}>
                      {run.run_name}
                    </td>
                    <td className="summary-cell" style={{ filter: "brightness(0.65)" }}>
                      {(run.statistics.distance / 1000).toFixed(2)} km
                    </td>
                    <td className="summary-cell" style={{ filter: "brightness(0.65)" }}>
                      {" "}
                      {run.statistics.duration.toFixed(2)} h
                    </td>
                    <td className="summary-cell" style={{ filter: "brightness(0.65)" }}>
                      {" "}
                      {idx === 0
                        ? run.statistics.route_fuel.toFixed(2)
                        : (run.statistics.route_fuel).toFixed(2)}{" "}
                      mt
                    </td>
                    <td className="summary-cell">
                      <button
                        className="download-button"
                        style={{ backgroundColor: run.color }}
                        onClick={() => this.downloadRoute(run.run_name,this.state.routes_data[idx])}
                      >
                        GEOJSON file
                      </button>
                        
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default CompareRuns;
