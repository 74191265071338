import Card from "react-bootstrap/Card";
import Carousel from 'react-bootstrap/Carousel';
import "./vesselCard.css";
import getCountryCode from "./isoCountries.js"
import { hasFlag } from 'country-flag-icons';

function VesselCard(props){
    const vessel = props.vessel;
    const images = vessel.photos.map((photo) => 
        process.env.REACT_APP_URL_BACKEND+"/uploads/vessel_images/"+photo.split("/").at(-1)
    );
    
    return (
        <Card className="vessel-card">
            {vessel ? (
            <>
                {images.length > 1 ? (
                    <Carousel>
                        {images.map((image) => (
                            <Carousel.Item >
                                <div className="crop-image">
                                    <img
                                        className="vessel-image"
                                        src={image}
                                        alt=""
                                    />
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                ) : (
                    <div className="crop-image">
                        <img
                            className="vessel-image"
                            src={images[0]}
                            alt=""
                        />
                    </div>
                )}
                <Card.Body>
                    <Card.Title
                        style={{ color: "#374790" }}
                        className="text-center"
                    >
                        {vessel.name + "       "}
                        {hasFlag(getCountryCode(vessel.current_flag)) ? (
                            <img
                            alt="Country flag"
                            className="country-icon"
                            src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/"+getCountryCode(vessel.current_flag)+".svg"}/>
                        ) : (
                            <></>
                        )}  
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>IMO</strong>: {vessel.imo}
                    </Card.Subtitle>
                    {vessel.mmsi ? (
                        <Card.Subtitle className="mb-2 text-muted">
                            <strong>MMSI</strong>: {vessel.mmsi}
                        </Card.Subtitle>
                    ) : ("")}
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Vessel type</strong>: {vessel.vessel_type}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Flag</strong>: {vessel.current_flag}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Length</strong>: {vessel.length} m
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Beam</strong>: {vessel.beam} m
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Draught</strong>: {vessel.draught} m
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Gross tonnage</strong>: {vessel.gross_tonnage} tons
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Summer DWT</strong>: {vessel.summer_dwt} tons
                    </Card.Subtitle>
                    {vessel.builder ? (
                        <Card.Subtitle className="mb-2 text-muted">
                            <strong>Builder</strong>: {vessel.builder}
                        </Card.Subtitle>
                    ) : ("")}
                    <Card.Subtitle className="mb-2 text-muted">
                        <strong>Build Year</strong>: {vessel.build_year}
                    </Card.Subtitle>
                    {vessel.owner ? (
                        <Card.Subtitle className="mb-2 text-muted">
                            <strong>Owner</strong>: {vessel.owner}
                        </Card.Subtitle>
                    ) : ("")}

                </Card.Body>
            </>
            ) : (
            <Card.Body> Loading... </Card.Body>
            )}
        </Card>
    );
}

export default VesselCard;