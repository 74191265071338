const base_api = process.env.REACT_APP_URL_BACKEND + "/";

function postRequisites(response) {
  if (response.status === 401 || response.status === 403) {
    localStorage.clear();
    window.location = "/";
  } else {
    localStorage.setItem("timestamp", Date.now());
  }
}

function preRequisites() {
  if (localStorage.getItem("token") && localStorage.getItem("timestamp")) {
    const timestamp = localStorage.getItem("timestamp");
    const now = Date.now();
    const diff = now - timestamp;
    const diffInMinutes = Math.floor(diff / 1000 / 60);
    if (diffInMinutes <= 60) return true;
  }

  localStorage.clear();
  window.location = "/";
  return false;
}

export async function login(userName, password) {
  try {
    const response = await fetch(base_api + "smartship/auth/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: userName,
        password: password,
      }),
    });

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function logout() {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/auth/logout/", {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    localStorage.clear();

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getAllVessels() {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/vessel/", {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getDates() {
  preRequisites();
  try {
    const response = await fetch(
      base_api + "smartship/optimization-api/available-dates",
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getOptimizersStructure() {
  preRequisites();
  try {
    const response = await fetch(
      base_api + "smartship/optimization-api/optimizers-structure/",
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getAllVesselsByColumns(columns) {
  preRequisites();
  try {
    const url_columns = columns.join("&");
    const response = await fetch(
      base_api + "smartship/vessel/get_columns/" + url_columns,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getVesselByIMO(imo) {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/vessel/" + imo, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getVesselImage(imo, image_index) {
  preRequisites();
  try {
    const response = await fetch(
      base_api + "uploads/vesel_images/" + imo + "_" + image_index + ".jpg",
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//All existing benchmarks stored in the backend
export async function getAllBenchmarks() {
  console.log(process.env);
  try {
    const response = await fetch(base_api + "smartship/benchmark/", {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//Retrive benchmark by key
export async function getBenchmark(key) {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/benchmark/" + key, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//All existing optimizer stored in the backend
export async function getAllOptimizers() {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/optimizer/", {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//All existing runs stored in the backend
export async function getAllRuns() {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/optimizer_run/", {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//Retrive run by id
export async function getRun(id) {
  preRequisites();
  try {
    const response = await fetch(base_api + "smartship/optimizer_run/" + id, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//Fetch route data (coordinates, weather and timestamps) from file
export async function getRouteData(file) {
  preRequisites();
  try {
    const response = await fetch(base_api + "uploads/routes/" + file, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//Fetch a list route data (coordinates, weather and timestamps) from file
export async function getListRouteData(files) {
  console.log(files);
  try {
    const responses = await Promise.all(
      files.map(async (file) => {
        const res = await fetch(base_api + "uploads/routes/" + file, {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        });
        return await res.json();
      })
    );
    return await responses;
  } catch (error) {
    console.log("Error reading files:", error);
    return [];
  }
}

//Fetch ocean data (wind, currents and waves for n days) from file
export async function getOceanData(file) {
  preRequisites();
  try {
    const response = await fetch(base_api + "uploads/ocean_data/" + file, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}

//Gets optimized route data from given coords and date
export async function getOptimized(
  lat_start,
  lon_start,
  lat_end,
  lon_end,
  date
) {
  preRequisites();
  try {
    //console.log(base_api + "smartship/optimization_api/" + lat_start + "&" + lon_start + "&" + lat_end + "&" + lon_end + "&" + date)
    const response = await fetch(
      base_api +
        "smartship/optimization_api/" +
        lat_start +
        "&" +
        lon_start +
        "&" +
        lat_end +
        "&" +
        lon_end +
        "&" +
        date,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );

    postRequisites(response);
    return await response.json();
  } catch (error) {
    return [];
  }
}
