import { Collapse, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";

function iconDefinition(open, iconName, color) {
  let openIcon = null;
  let closeIcon = null;
  if (iconName === "arrow") {
    openIcon = <ExpandLessIcon color={color} />;
    closeIcon = <ExpandMoreIcon color={color} />;
  } else if (iconName === "add") {
    openIcon = <RemoveIcon color={color} />;
    closeIcon = <AddIcon color={color} />;
  }

  return open ? openIcon : closeIcon;
}

function Collapsible(props) {
  const startOpen = props.startOpen ? props.startOpen : false;
  const [open, setOpen] = useState(startOpen);
  const iconname = props.iconName ? props.iconName : "arrow";
  const iconPosition = props.iconPosition ? props.iconPosition : "end";
  const icon = iconDefinition(open, iconname, props.color);

  return (
    <div>
      <Button
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-controls="collapse"
        style={{ color: props.color, textTransform: "none" }}
        disableRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        endIcon={iconPosition === "end" ? icon : null}
        startIcon={iconPosition === "start" ? icon : null}
        className={props.buttonClass}
      >
        {props.text}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
}

export default Collapsible;
