import React, { useState } from "react";
import { login } from "../../services/Benchmark_Backend";
import { Card, Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import shipImage from "../../assets/vertical_vessel.jpg";
import logo from "../../assets/logo.svg";
import "./Login.css";
import { TextField, Button } from "@mui/material";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    login(userName, password).then((data) => {
      if (data.key) {
        localStorage.setItem("userName", userName);
        localStorage.setItem("token", data.key);
        localStorage.setItem("timestamp", Date.now());
        window.location = "/demo";
      } else {
        localStorage.clear();
      }
    });
  };

  return (
    <Card className="login-card">
      <Row>
        <Col
          style={{
            overflow: "hidden",
          }}
          className="justify-content-center p-0"
        >
          <Image src={shipImage} style={{ height: "100%", maxWidth: "100%" }} />
        </Col>
        <Col className="p-0">
          <Row className="display-flex justify-content-center p-0">
            <Image
              src={logo}
              style={{ height: "auto", width: "90%", padding: "30px" }}
            />
          </Row>
          <br />
          <Row className="display-flex justify-content-center p-0">
            <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
              <Row className="m-auto w-75">
                <TextField
                  type="text"
                  label="Username"
                  variant="outlined"
                  onChange={(e) => setUserName(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                />
              </Row>
              <Row className="m-auto w-75">
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                />
              </Row>
              <Row className="display-flex justify-content-center m-auto w-75">
                <Button
                  type="submit"
                  sx={{ marginBottom: "15px" }}
                  style={{ width: "70%" }}
                >
                  Login
                </Button>
              </Row>
            </form>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default Login;
