import React, { useState, useEffect } from "react";
import {
  getAllVessels,
  getDates,
  getOptimizersStructure,
} from "../../services/Benchmark_Backend";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import VesselCard from "../../components/VesselCard/VesselCard";
import { Link } from "react-router-dom";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import MiniMap from "../../components/Map/MiniMap";
import Collapsible from "../../components/Collapsible/Collapsible";
import { colors } from "../../theme";
import Card from "react-bootstrap/Card";

import "./DemoSelection.css";
import _ from "lodash";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function getAllTypes(array) {
  var types = [];
  array.forEach((vessel) => {
    types.push(vessel.vessel_type);
  });
  types = types.filter(onlyUnique).sort();

  return types.map((type) => ({ value: type, label: type }));
}

function getMaxVariable(array, variable) {
  var max = 0;
  array.forEach((vessel) => {
    if (vessel[variable] >= max) {
      max = vessel[variable];
    }
  });
  return max;
}

function getMinVariable(array, variable) {
  var min = array[0][variable];
  array.forEach((vessel) => {
    if (vessel[variable] <= min) {
      min = vessel[variable];
    }
  });
  return min;
}

function filterAvailablePorts(availablePorts, alreadySelectedPort) {
  if (availablePorts === null) {
    return [];
  }
  let ports = Object.keys(availablePorts);
  if (alreadySelectedPort != null) {
    let portIndex = ports.indexOf(alreadySelectedPort);
    ports.splice(portIndex, 1);
  }

  return ports.map((port) => ({ value: port, label: port }));
}

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 60,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

function todayDate() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

const DemoSelection = () => {
  //list of items
  const [vessels, setVessels] = useState([]);
  const [filtered_vessels, setFilteredVessels] = useState([]);
  const [selectedType, setSelectedType] = useState("All");
  const [allVesselTypes, setAllVesselTypes] = useState([]);
  const [max_variables, setMaxVariables] = useState({
    beam: 100,
    length: 450,
    draught: 30,
    gross_tonnage: 420000,
    summer_dwt: 400000,
  });
  const [min_variables, setMinVariables] = useState({
    beam: 0,
    length: 0,
    draught: 0,
    gross_tonnage: 0,
    summer_dwt: 0,
  });
  const [availablePorts, setAvailablePorts] = useState(null);
  const [availableDates, setAvailableDates] = useState(null);
  const [availableOptimizers, setAvailableOptimizers] = useState(null);

  //Selected items
  const [date, setDate] = useState(null);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState([null, null]);
  const [selectedVelocity, setSelectedVelocity] = useState(10);
  const [selectedOptimizers, setSelectedOptimizers] = useState({});

  useEffect(() => {
    getAllVessels().then((data) => {
      data = data.sort((a, b) => (a.name > b.name ? 1 : -1));
      setSelectedVessel(data[0]);
      setVessels(data);
      setFilteredVessels(data);
      setAllVesselTypes(
        [{ label: "All", value: "All" }].concat(getAllTypes(data))
      );
      setMaxVariables({
        beam: getMaxVariable(data, "beam"),
        length: getMaxVariable(data, "length"),
        draught: getMaxVariable(data, "draught"),
        gross_tonnage: getMaxVariable(data, "gross_tonnage"),
        summer_dwt: getMaxVariable(data, "summer_dwt"),
      });
      setMinVariables({
        beam: getMinVariable(data, "beam"),
        length: getMinVariable(data, "length"),
        draught: getMinVariable(data, "draught"),
        gross_tonnage: getMinVariable(data, "gross_tonnage"),
        summer_dwt: getMinVariable(data, "summer_dwt"),
      });
    });

    fetch(process.env.REACT_APP_URL_BACKEND + "/uploads/ports_available.json")
      .then((response) => response.json())
      .then((data) => setAvailablePorts(data));

    getDates().then((data) => {
      setAvailableDates(data);
    });

    getOptimizersStructure().then((data) => {
      setAvailableOptimizers(data);
      let optimizers = {};
      optimizers["CircumnavigateOptimizer"] = selectDefaultValues(
        data["CircumnavigateOptimizer"]
      );
      optimizers["AstarOptimizer"] = selectDefaultValues(
        data["AstarOptimizer"]
      );
      setSelectedOptimizers(optimizers);
    });
  }, []);

  useEffect(() => {
    let vesselsData = [...vessels];
    if (selectedType !== "All") {
      vesselsData = vesselsData.filter(
        (vessel) => vessel.vessel_type === selectedType
      );
    }
    vesselsData = vesselsData.filter(
      (vessel) =>
        vessel.draught <= max_variables["draught"] &&
        vessel.draught >= min_variables["draught"]
    );
    vesselsData = vesselsData.filter(
      (vessel) =>
        vessel.beam <= max_variables["beam"] &&
        vessel.beam >= min_variables["beam"]
    );
    vesselsData = vesselsData.filter(
      (vessel) =>
        vessel.length <= max_variables["length"] &&
        vessel.length >= min_variables["length"]
    );
    vesselsData = vesselsData.filter(
      (vessel) =>
        vessel.gross_tonnage <= max_variables["gross_tonnage"] &&
        vessel.gross_tonnage >= min_variables["gross_tonnage"]
    );
    vesselsData = vesselsData.filter(
      (vessel) =>
        vessel.summer_dwt <= max_variables["summer_dwt"] &&
        vessel.summer_dwt >= min_variables["summer_dwt"]
    );

    const all_imos = vesselsData.map((vessel) => vessel.imo);

    if (vesselsData.length > 0 && !all_imos.includes(selectedVessel.imo)) {
      setSelectedVessel(vesselsData[0]);
    }
    setFilteredVessels(vesselsData);
  }, [selectedType, max_variables, min_variables, vessels, selectedVessel]);

  function getByIMO(imo, vessels_array) {
    for (var i = 0; i < vessels_array.length; i++) {
      if (vessels_array[i].imo === imo) {
        return vessels_array[i];
      }
    }
  }

  function selectDefaultValues(optimizerOject) {
    let data = { ...optimizerOject };
    Object.keys(data).forEach((key, index) => {
      data[key] = data[key].default;
    });

    return data;
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  function optimizerVariableComponent(variable, value, callback) {
    if (variable.values.min !== undefined) {
      let step = 1;
      if (variable.type === "FloatField") step = 0.01;
      // TODO: add onChange event handling
      return (
        <IOSSlider
          key="length_slider"
          className="filter-slider"
          value={value}
          step={step}
          min={variable.values.min}
          max={variable.values.max}
          valueLabelDisplay="on"
          aria-label="ios slider"
          onChange={(e) => callback(e.target.value)}
        />
      );
    } else if (variable.values.values !== undefined) {
      return (
        <Select
          value={{ label: value, value: value }}
          isMulti={false}
          options={variable.values.values.map((value) => ({
            label: value,
            value: value,
          }))}
          menuPortalTarget={document.body}
          className="vessel-filter-selector"
          onChange={(e) => callback(e.value)}
        />
      );
    }
  }

  return (
    <>
      {selectedVessel ? (
        <Row className="justify-content-md-center">
          <Col lg="5">
            <VesselCard vessel={selectedVessel} />
          </Col>

          <Col lg="5">
            <Card className="section-card">
              <Collapsible
                color={colors["primary-blue"]}
                title="Vessel"
                iconName="add"
                iconPosition="start"
                startOpen={true}
                text="Vessel"
                buttonClass="section-title"
              >
                <Select
                  value={{
                    value: selectedVessel.imo,
                    label: selectedVessel.name,
                  }}
                  isMulti={false}
                  options={filtered_vessels.map((vessel) => ({
                    value: vessel.imo,
                    label: vessel.name,
                  }))}
                  onChange={(selected) => {
                    setSelectedVessel(getByIMO(selected.value, vessels));
                  }}
                  className="vessel-selector"
                />
                <Collapsible
                  color={colors["primary-blue"]}
                  text="Filters"
                  //containerElementProps={{className:"vessel-filter"}}
                >
                  <Row style={{ margin: "10px" }}>
                    <Row>
                      <Col className="vessel-filter-section">
                        <h6 className="blue-text">Vessel type:</h6>
                        <Select
                          defaultValue={{ label: "All", value: "All" }}
                          isMulti={false}
                          options={allVesselTypes}
                          onChange={(selected) => {
                            setSelectedType(selected.value);
                          }}
                          menuPortalTarget={document.body}
                          className="vessel-filter-selector"
                        />
                      </Col>
                      <Col className="vessel-filter-section">
                        <h6 className="blue-text">Length (m):</h6>
                        <IOSSlider
                          key="length_slider"
                          className="filter-slider"
                          defaultValue={[0, 450]}
                          min={0}
                          max={450}
                          valueLabelDisplay="on"
                          aria-label="ios slider"
                          onChange={(e) => {
                            const value = e.target.value;
                            min_variables["length"] = value[0];
                            max_variables["length"] = value[1];
                            setMinVariables({ ...min_variables });
                            setMaxVariables({ ...max_variables });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="vessel-filter-section">
                        <h6 className="blue-text">Gross tonnage (tons):</h6>
                        <IOSSlider
                          key="gross_tonnage_slider"
                          className="filter-slider"
                          defaultValue={[0, 420000]}
                          min={0}
                          max={420000}
                          valueLabelDisplay="on"
                          aria-label="ios slider"
                          onChange={(e) => {
                            const value = e.target.value;
                            min_variables["gross_tonnage"] = value[0];
                            max_variables["gross_tonnage"] = value[1];
                            setMinVariables({ ...min_variables });
                            setMaxVariables({ ...max_variables });
                          }}
                        />
                      </Col>
                      <Col className="vessel-filter-section">
                        <h6 className="blue-text">Beam (m):</h6>
                        <IOSSlider
                          key="beam_slider"
                          className="filter-slider"
                          defaultValue={[0, 100]}
                          min={0}
                          max={100}
                          valueLabelDisplay="on"
                          aria-label="ios slider"
                          onChange={(e) => {
                            const value = e.target.value;
                            min_variables["beam"] = value[0];
                            max_variables["beam"] = value[1];
                            setMinVariables({ ...min_variables });
                            setMaxVariables({ ...max_variables });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="vessel-filter-section">
                        <h6 className="blue-text">Summer DWT (tons):</h6>
                        <IOSSlider
                          key="summer_dwt_slider"
                          className="filter-slider"
                          defaultValue={[0, 400000]}
                          min={0}
                          max={400000}
                          valueLabelDisplay="on"
                          aria-label="ios slider"
                          onChange={(e) => {
                            const value = e.target.value;
                            min_variables["summer_dwt"] = value[0];
                            max_variables["summer_dwt"] = value[1];
                            setMinVariables({ ...min_variables });
                            setMaxVariables({ ...max_variables });
                          }}
                        />
                      </Col>

                      <Col className="vessel-filter-section">
                        <h6 className="blue-text">Draught (m):</h6>
                        <IOSSlider
                          key="draught_slider"
                          className="filter-slider"
                          defaultValue={[0, 30]}
                          min={0}
                          max={30}
                          valueLabelDisplay="on"
                          aria-label="ios slider"
                          onChange={(e) => {
                            const value = e.target.value;
                            min_variables["draught"] = value[0];
                            max_variables["draught"] = value[1];
                            setMinVariables({ ...min_variables });
                            setMaxVariables({ ...max_variables });
                          }}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Collapsible>
              </Collapsible>
            </Card>
            <Card className="section-card">
              <Collapsible
                color={colors["primary-blue"]}
                title="Vessel"
                iconName="add"
                iconPosition="start"
                startOpen={true}
                text="Route"
                buttonClass="section-title"
              >
                <Row>
                  <Col>
                    <h6 className="blue-text">Origin:</h6>
                    <Select
                      value={{
                        value: selectedRoute[0],
                        label: selectedRoute[0],
                      }}
                      isMulti={false}
                      options={filterAvailablePorts(
                        availablePorts,
                        selectedRoute[1]
                      )}
                      onChange={(selected) => {
                        setSelectedRoute([selected.value, selectedRoute[1]]);
                      }}
                      className="route-selector"
                    />
                  </Col>
                  <Col>
                    <h6 className="blue-text">Destination:</h6>
                    <Select
                      value={{
                        value: selectedRoute[1],
                        label: selectedRoute[1],
                      }}
                      isMulti={false}
                      options={filterAvailablePorts(
                        availablePorts,
                        selectedRoute[0]
                      )}
                      onChange={(selected) => {
                        setSelectedRoute([selectedRoute[0], selected.value]);
                      }}
                      className="route-selector"
                    />
                  </Col>
                </Row>

                {selectedRoute[0] && selectedRoute[1] ? (
                  <Collapsible
                    color={colors["primary-blue"]}
                    text="Show map"
                    //containerElementProps={{className:"vessel-filter"}}
                  >
                    <Card className="map-wrapper map-card">
                      <MiniMap
                        startCoords={[
                          availablePorts[selectedRoute[0]].lat,
                          availablePorts[selectedRoute[0]].lon,
                        ]}
                        endCoords={[
                          availablePorts[selectedRoute[1]].lat,
                          availablePorts[selectedRoute[1]].lon,
                        ]}
                        startName={selectedRoute[0]}
                        endName={selectedRoute[1]}
                      />
                    </Card>
                  </Collapsible>
                ) : (
                  ""
                )}
              </Collapsible>
            </Card>
            <Card className="section-card">
              <Collapsible
                color={colors["primary-blue"]}
                title="Vessel"
                iconName="add"
                iconPosition="start"
                startOpen={true}
                text="Departure Date"
                buttonClass="section-title"
              >
                <input
                  id="departing-datepicker"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  type="date"
                  className=" form-control"
                  placeholder="yyyy-mm-dd"
                  min={availableDates ? availableDates[0] : null}
                  //today
                  max={todayDate()}
                ></input>
              </Collapsible>
            </Card>
            <Card className="section-card">
              <Collapsible
                color={colors["primary-blue"]}
                title="Vessel"
                iconName="add"
                iconPosition="start"
                startOpen={false}
                text="Optimizers"
                buttonClass="section-title"
              >
                {availableOptimizers ? (
                  <>
                    <Select
                      defaultValue={Object.keys(selectedOptimizers).map(
                        (key) => ({
                          value: selectedOptimizers[key],
                          label: key,
                        })
                      )}
                      isMulti={true}
                      options={Object.keys(availableOptimizers).map(
                        (optimizerKey) => ({
                          value: selectDefaultValues(
                            availableOptimizers[optimizerKey]
                          ),
                          label: optimizerKey,
                        })
                      )}
                      hideSelectedOptions={true}
                      onChange={(selected) => {
                        let optimizers = {};
                        selected.forEach((optimizer) => {
                          optimizers[optimizer.label] = optimizer.value;
                        });
                        setSelectedOptimizers(optimizers);
                      }}
                      filterOption={(candidate, input) => {
                        return !Object.keys(selectedOptimizers).includes(
                          candidate.label
                        );
                      }}
                      className="vessel-selector"
                    />
                    {Object.keys(selectedOptimizers).map((key) => {
                      let optimizer = availableOptimizers[key];
                      let variables = Object.keys(optimizer);

                      let variablesChunked = _.chunk(variables, 2);

                      return (
                        <Collapsible
                          color={colors["primary-blue"]}
                          text={key.replace("Optimizer", " Optimizer")}
                        >
                          <Row style={{ margin: "10px" }}>
                            {variablesChunked.map((chunk) => {
                              return (
                                <Row>
                                  {/*TODO: refactor this*/}
                                  {chunk.map((variable) => (
                                    <Col className="vessel-filter-section">
                                      <>
                                        <h6 className="blue-text">
                                          {capitalize(
                                            variable.replace("_", " ")
                                          )}
                                        </h6>
                                        {optimizerVariableComponent(
                                          optimizer[variable],
                                          selectedOptimizers[key][variable],
                                          (value) => {
                                            let selectedOptimizersCopy = {
                                              ...selectedOptimizers,
                                            };

                                            selectedOptimizersCopy[key][
                                              variable
                                            ] = value;
                                            setSelectedOptimizers(
                                              selectedOptimizersCopy
                                            );
                                          }
                                        )}
                                      </>
                                    </Col>
                                  ))}
                                </Row>
                              );
                            })}
                          </Row>
                        </Collapsible>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </Collapsible>
            </Card>
            <Card className="section-card">
              <Collapsible
                color={colors["primary-blue"]}
                title="Vessel"
                iconName="add"
                iconPosition="start"
                startOpen={false}
                text="Extra"
                buttonClass="section-title"
              >
                <Row>
                  <Col className="vessel-filter-section">
                    <h6 className="blue-text">Velocity (knots):</h6>
                    <IOSSlider
                      className="velocity-slider filter-slider"
                      defaultValue={selectedVelocity}
                      min={5}
                      max={20}
                      valueLabelDisplay="on"
                      aria-label="ios slider"
                      onChange={(e) => {
                        setSelectedVelocity(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Collapsible>
            </Card>

            <div className="d-grid gap-2">
              <Link
                className={`text-center run-button mt-2 ${
                  !selectedRoute[0] ||
                  !selectedRoute[1] ||
                  !selectedVessel ||
                  !date
                    ? "run-button-disabled"
                    : ""
                }`}
                // TODO: add link to the route page
                to={""}
              >
                {" "}
                <h4>Optimize Route</h4>{" "}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

export default DemoSelection;
