import {
    MapContainer,
    TileLayer,
    Marker,
    Pane,
} from "react-leaflet";import CustomMarker from "../../map_layers/CustomMarker";

import {colors} from "../../theme";
import L from "leaflet";


function MiniMap(props){
    const blackMap =
        "https://api.mapbox.com/styles/v1/tr3cks/cl3dg82dx002914rsbsbmmpwy/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidHIzY2tzIiwiYSI6ImNrdjZpZnp1eDB2dG4ycW9rMjlmOHY0OHIifQ.egCphR_INbD25yU6Ha4A8w";
    const blackBackground = "#2e2e2e";

    const bounds = L.latLngBounds(L.latLng(-90, -360), L.latLng(90, 360));

    let center = Array(2).fill(0);
    if (props.start_coords && props.end_coords) {
        center = [
        (parseFloat(props.start_coords.at(0)) +
            parseFloat(props.end_coords.at(0))) /
            2,
        (parseFloat(props.start_coords.at(1)) +
            parseFloat(props.end_coords.at(1))) /
            2,
        ];
    }

    return(
        <MapContainer
        key={blackBackground}
        center={center}
        zoom={2}
        scrollWheelZoom={true}
        maxZoom={8}
        minZoom={1}
        style={{ background: blackBackground }}
        maxBounds={bounds}
        maxBoundsViscosit={1.0}
        attributionControl={false}
        >

            <Pane name="map" style={{ zIndex: 500 }}>
                <TileLayer
                url={blackMap}
                />
            </Pane>

            <Pane name="Markers" style={{ zIndex: 650 }}>
                <Marker
                    position={props.startCoords}
                    icon={CustomMarker(colors["light-green"], props.startName)}
                />
                <Marker
                    position={props.endCoords}
                    icon={CustomMarker(colors["light-blue"], props.endName)}
                />
            </Pane>

        </MapContainer>
    );
}

export default MiniMap;