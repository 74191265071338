import "leaflet-velocity";
import L from "leaflet";
import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";

const Velocity = (props) => {
  const context = useLeafletContext();
  useEffect(() => {
    var lineWidth = props.lineWidth;
    if (props.lineWidth === undefined) {
      lineWidth = 1;
    }

    const velocity = new L.velocityLayer({
      displayValues: true,
      data: props.data,
      displayOptions: {
        velocityType: props.id,
        position: "bottomleft",
        emptyString: "No velocity data",
        angleConvention: "bearingCW",
        displayPosition: "bottomleft",
        displayEmptyString: "No velocity data",
        speedUnit: "ms",
      },

      // OPTIONAL
      lineWidth: lineWidth,
      velocityScale: props.velocityScale, // modifier for particle animations, arbitrarily defaults to 0.005
      colorScale: props.colorScale, // define your own array of hex/rgb colors
      maxVelocity: Math.max(Math.max(props.data), Math.max(props.data)),
      minVelocity: Math.min(Math.min(props.data), Math.min(props.data)),
      particleAge: props.particleAge, // age of the particles
      paneName: props.paneName, // pane name for the layer
    });

    const container = context.layerContainer || context.map;
    container.addLayer(velocity);

    return () => {
      container.removeLayer(velocity);
    };
  });

  return null;
};

export default Velocity;
