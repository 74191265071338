import "./App.css";
import TopNavbar from "./components/TopNavbar/TopNavbar";
import Routes from "./Routes";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";

function App() {
  let location = useLocation().pathname;
  return (
    <>
      {location === "/login" ? null : <TopNavbar />}
      <Row className="pt-5 mb-5 justify-content-md-center">
        <Col lg="10">
          <Routes />
        </Col>
      </Row>
    </>
  );
}

export default App;
