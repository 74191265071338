import React, { useState, useEffect } from "react";
import { getAllBenchmarks } from "../../services/Benchmark_Backend";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const Benchmarks = () => {
  //list of items
  const [benchmarks, setBenchmarks] = useState([]);

  useEffect(() => {
    const response_benchmarks = getAllBenchmarks();

    response_benchmarks.then((data) => {
      setBenchmarks(data);
      console.log(data);
    });
  }, []);

  return (
    <>
      <h1 className="text-center mb-5">Available Benchmarks</h1>

      <Row className=" mt-5">
        {benchmarks.map((benchark) => (
          <Col className="d-flex justify-content-center mt-3" lg="4">
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <Card.Title>
                  <Link to={"/compare_runs$?key=" + benchark.key}>
                    {" "}
                    {benchark.key}{" "}
                  </Link>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {" "}
                  Point A to Point B{" "}
                </Card.Subtitle>
                <Card.Text>
                  Difficulty: {Math.floor(Math.random() * 10)}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Benchmarks;
