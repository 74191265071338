import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Pane,
  useMapEvents,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import "./Map.css";
import L from "leaflet";
import Velocity from "../../map_layers/velocity";
import Spline from "../../map_layers/spline";
import HeatmapLayer from "../../map_layers/HeatmapLayer";
import CustomMarker from "../../map_layers/CustomMarker";
import CustomCircleMarker from "../../map_layers/CustomCircleMarker";

import { useEffect } from "react";

function GetIndicesOfDay(first_route, route, day) {
  var date = daysOfRoute(first_route).at(day);
  var coords = [];
  route.timestamps.forEach((ts, idx) => {
    if (getDateDayString(ts) === date) {
      coords.push(idx);
    }
  });
  return coords;
}

function getDateDayString(dateString) {
  let date = new Date(dateString);
  date.setTime(date.getTime()+ 2* 60 * 60 * 1000);
  date.setSeconds(date.getSeconds() + 10);
  return date.toISOString().split("T").at(0);
}

function daysOfRoute(route) {
  return lodash.uniq(route.timestamps.map((i) => getDateDayString(i)));
}

const lodash = require("lodash");
function Map(props) {
  //Init state
  const [startCoords, setStartCoords] = useState(props.start_coords);
  const [endCoords, setEndCoords] = useState(props.end_coords);
  const [visibleLegend, setVisibleLegend] = useState(false);

  const interactive = props.interactive;
  const setCoords = props.setCoords;

  const blueMap =
    "https://api.mapbox.com/styles/v1/tr3cks/cl46tkaxq000g15p78nohc1qg/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidHIzY2tzIiwiYSI6ImNrdjZpZnp1eDB2dG4ycW9rMjlmOHY0OHIifQ.egCphR_INbD25yU6Ha4A8w";
  const blueBackground = "#a0c7ee";

  const blackMap =
    "https://api.mapbox.com/styles/v1/tr3cks/cl3dg82dx002914rsbsbmmpwy/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidHIzY2tzIiwiYSI6ImNrdjZpZnp1eDB2dG4ycW9rMjlmOHY0OHIifQ.egCphR_INbD25yU6Ha4A8w";
  const blackBackground = "#2e2e2e";

  const [mapBackground, setMapBackground] = useState(blackBackground);

  const SelectCoords = () => {
    useMapEvents({
      click(e) {
        if (!startCoords) {
          setStartCoords(e.latlng);
          console.log("start: ", e.latlng);
        } else if (!endCoords) {
          setEndCoords(e.latlng);
          console.log("end: ", e.latlng);
        }
      },
    });
  };

  let setEvents = (mapRef) => {
    const map = mapRef.target;
    map.on("overlayadd", (e) => {
      //do whatever
      if (e.name === "Waves") setVisibleLegend(true);
    });

    map.on("overlayremove", (e) => {
      //do whatever
      if (e.name === "Waves") setVisibleLegend(false);
    });
  };

  useEffect(() => {
    if (interactive) setCoords({ start: startCoords, end: endCoords });
  }, [startCoords, endCoords, interactive, setCoords]);

  const current_colorScale = [
    "#d9843f",
    "#d78039",
    "#d67d33",
    "#d4792d",
    "#d27526",
    "#d0711f",
    "#ce6e18",
    "#cc6a0f",
    "#ca6603",
  ];

  const wind_colorScale = [
    "#fafa6e",
    "#d7f171",
    "#b5e877",
    "#95dd7d",
    "#77d183",
    "#5bc489",
    "#3fb78d",
    "#23aa8f",
    "#009c8f",
    "#008d8c",
    "#007f86",
    "#0b717e",
    "#1c6373",
    "#255566",
    "#2a4858",
  ];

  const waves_colorScale = ["#ffffff"];

  const bounds = L.latLngBounds(L.latLng(-90, -540), L.latLng(90, 540));

  let center = Array(2).fill(0);
  if (props.start_coords && props.end_coords) {
    center = [
      (parseFloat(props.start_coords.at(0)) +
        parseFloat(props.end_coords.at(0))) /
        2,
      (parseFloat(props.start_coords.at(1)) +
        parseFloat(props.end_coords.at(1))) /
        2,
    ];
  }

  return (
    <>
      <Container style={{ margin: 0, padding: 0 }}>
        <div className="toggle-switch">
          <input
            type="checkbox"
            className="checkbox"
            onClick={() => {
              mapBackground === blueBackground
                ? setMapBackground(blackBackground)
                : setMapBackground(blueBackground);
            }}
            name="Theme"
            id="Theme"
          />
          <label className="label" htmlFor="Theme">
            <span className="inner" />
            <span className="switch" />
          </label>
        </div>
      </Container>
      <MapContainer
        key={mapBackground}
        center={center}
        zoom={4}
        scrollWheelZoom={true}
        maxZoom={8}
        minZoom={2.5}
        style={{ background: mapBackground }}
        maxBounds={bounds}
        maxBoundsViscosit={1.0}
        attributionControl={false}
        whenReady={setEvents}
      >
        <LayersControl position="topright">
          {interactive ? (
            <SelectCoords
              startCoords={startCoords}
              setStartCoords={setStartCoords}
              endCoords={endCoords}
              setEndCoords={setEndCoords}
            />
          ) : (
            ""
          )}

          <Pane name="map" style={{ zIndex: 500 }}>
            <TileLayer
              url={mapBackground === blueBackground ? blueMap : blackMap}
            />
          </Pane>

          <Pane name="Markers" style={{ zIndex: 650 }}>
            {startCoords ? (
              <Marker
                position={startCoords}
                icon={CustomMarker("#50C878", props.city_start)}
                eventHandlers={{
                  click: (e) => {
                    if (interactive) {
                      setStartCoords(null);
                    }
                  },
                }}
              />
            ) : (
              ""
            )}

            {endCoords ? (
              <Marker
                position={endCoords}
                icon={CustomMarker("#C70039", props.city_end)}
                eventHandlers={{
                  click: (e) => {
                    if (interactive) {
                      setEndCoords(null);
                    }
                  },
                }}
              />
            ) : (
              ""
            )}
          </Pane>

          <Pane name="Routes" style={{ zIndex: 600 }}>
            {props.routes.length > 0
              ? props.routes.map((route, routeIdx) => (
                  <>
                    {props.smooth_rate > 0 ? (
                      <>
                        <Spline
                          pathOptions={{
                            color: props.routes_colors[routeIdx],
                            weight: 1.5,
                            smoothing:props.smooth_rate,
                          }}
                          positions={route.coords}
                        />
                        <Spline
                          pathOptions={{
                            color: props.routes_colors[routeIdx],
                            weight: 5,
                            smoothing:props.smooth_rate,
                          }}
                          positions={route.coords
                            .map((coords, routeIdx) =>
                              GetIndicesOfDay(
                                props.routes[0],
                                route,
                                props.CurrentDayIndex
                              ).includes(routeIdx)
                                ? coords
                                : null
                            )
                            .filter((n) => n)}
                        />
                      </>
                    ) : (
                      <>
                        <Polyline
                          pathOptions={{
                            color: props.routes_colors[routeIdx],
                            weight: 1.5,
                            smoothing:0.3,
                          }}
                          positions={route.coords}
                        />
                        <Polyline
                          pathOptions={{
                            color: props.routes_colors[routeIdx],
                            weight: 5,
                          }}
                          positions={route.coords
                            .map((coords, routeIdx) =>
                              GetIndicesOfDay(
                                props.routes[0],
                                route,
                                props.CurrentDayIndex
                              ).includes(routeIdx)
                                ? coords
                                : null
                            )
                            .filter((n) => n)}
                        />
                      </>
                    )};

                    {props.circlesIndices[routeIdx].map((coordIdx) => (
                      <>
                      <CustomCircleMarker
                        center={route.coords[coordIdx]}
                        radius={
                          GetIndicesOfDay(
                            props.routes[0],
                            route,
                            props.CurrentDayIndex
                          ).includes(coordIdx)
                            ? 5
                            : 2
                        }
                        color={props.routes_colors[routeIdx]}
                        popupText={`
                        <div class="col">
                        <div><b>Currents controbution:</b> ${route.weather.currents_contribution[
                          coordIdx
                        ].toFixed(2)}</div>
                        <div><b>Wind contribution:</b> ${route.weather.wind_contribution[
                          coordIdx
                        ].toFixed(2)}</div>
                        <div><b>Waves height:</b> ${route.weather.waves[
                          coordIdx
                        ].toFixed(2)}</div>
                        </div>
                        `}
                        pane="Routes"
                      />
                      </>
                    ))}
                  </>
                ))
              : console.log("No routes to display")}
          </Pane>
          {props.ocean ? (
            <>
              <Pane name="Wind" style={{ zIndex: 550 }}>
                <LayersControl.Overlay name="Wind">
                  <LayerGroup>
                    <Velocity
                      id="Wind"
                      velocityScale={0.01}
                      colorScale={wind_colorScale}
                      data={props.ocean.wind.at(props.CurrentDayIndex)}
                      paneName="Wind"
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
              </Pane>

              <Pane name="Ocean" style={{ zIndex: 400 }}>
                <LayersControl.Overlay checked name="Currents">
                  <LayerGroup>
                    <Velocity
                      id="Currents"
                      velocityScale={0.3}
                      colorScale={current_colorScale}
                      data={props.ocean.currents.at(props.CurrentDayIndex)}
                      paneName="Ocean"
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name="Waves">
                  <LayerGroup>
                    <HeatmapLayer
                      url="https://nrt.cmems-du.eu/thredds/wms/global-analysis-forecast-wav-001-027?"
                      layer="VHM0"
                      layerStyle="boxfill/occam"
                      layerConfig={{
                        time: new Date(props.CurrentDay).toISOString(),
                      }}
                    />

                    <Velocity
                      id="Waves"
                      velocityScale={0.005}
                      colorScale={waves_colorScale}
                      data={props.ocean.waves.at(props.CurrentDayIndex)}
                      lineWidth={6}
                      particleAge={60}
                      paneName="Ocean"
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
              </Pane>
              <Container
                className="shadow-lg"
                style={{ visibility: visibleLegend ? "visible" : "hidden" }}
              >
                <Row className="heatmap-legend">
                  <Col className="text-center"> m/s </Col>
                  <Col className="text-center"> 2.5 </Col>
                  <Col className="text-center"> 5 </Col>
                  <Col className="text-center"> 7.5 </Col>
                  <Col className="text-center"> 10 </Col>
                </Row>
              </Container>
            </>
          ) : (
            ""
          )}
        </LayersControl>
      </MapContainer>
    </>
  );
}

export default Map;
