import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "./topNavBar.css";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { logout } from "../../services/Benchmark_Backend";

const manageOnClick = () => {
  logout().then((data) => (window.location = "/"));
};

const topNavbar = () => {
  return (
    <Navbar
      className="custom-navbar"
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Container>
        <Navbar.Brand href="/">SmartShipping</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/*<Nav.Link href="/results">Results</Nav.Link>*/}
            <Nav.Link href="/benchmarks">Benchmarks</Nav.Link>
            <Nav.Link href="/demo">Demo</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse
          className="justify-content-end"
          id="responsive-navbar-nav"
        >
          <Nav>
            <Nav.Link onClick={manageOnClick}>
              Logout <PowerSettingsNewIcon />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default topNavbar;
