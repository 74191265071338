import Slider from "@mui/material/Slider";
import React from "react";
import Map from "./Map";

function MapWrapper(props) {
  //Init state
  const [CurrentDayIndex, setCurrentDayIndex] = React.useState(0);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const get_days = (dateStrings) => {
    const days = [];
    dateStrings.forEach(dateString => {
      days.push(dateString.split(" ")[0]);
    });
    return days.filter(onlyUnique);
  };

  const range = (number) => {
    var array = [];
    for (var i = 0; i < number; i++) {
      array.push(i);
    }
    return array;
  };

  let max_length = 1;
  if (props.routes)
    var route_lengths = props.routes.map((route) => get_days(route.timestamps).length);
    var new_max_length = Math.max(...route_lengths);
    if (new_max_length > max_length)
      max_length = new_max_length;

  return (
    <>
      <Map
        routes={props.routes}
        routes_colors={props.routes_colors}
        city_start={props.city_start}
        city_end={props.city_end}
        start_coords={props.start_coords}
        end_coords={props.end_coords}
        CurrentDayIndex={CurrentDayIndex}
        CurrentDay={
          new Date(
            new Date(props.start_date).getTime() +
              CurrentDayIndex * 24 * 60 * 60 * 1000
          )
        }
        ocean={props.ocean}
        circlesIndices = {props.circlesIndices}
        smooth_rate = {props.smooth_rate}
      />

      {props.ocean ? (
        <Slider
          min={1}
          max={max_length}
          aria-label="Default"
          marks={range(max_length).map((_, idx) => ({
            value: idx + 1,
            label: "Day " + (idx + 1),
          }))}
          step={null}
          onChange={(e) => {
            setCurrentDayIndex(e.target.value - 1);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MapWrapper;
