import L from "leaflet";
import "leaflet-spline";
import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";

const Spline = (props) => {
  const context = useLeafletContext();
  useEffect(() => {
    const spline = new L.spline(props.positions,props.pathOptions);

    const container = context.layerContainer || context.map;
    container.addLayer(spline);

    return () => {
      container.removeLayer(spline);
    };
  });

  return null;
};

export default Spline;
