import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";

const HeatmapLayer = (props) => {
  const context = useLeafletContext();
  useEffect(() => {
    //Convert array to json with lat, lon and height

    const container = context.layerContainer || context.map;
    let WMS_config = {
      service: "WMS",
      request: "GetMap",
      layers: props.layer,
      styles: props.layerStyle,
      format: "image/png",
      transparent: true,
      version: "1.3.0",
    };

    for (let key in props.layerConfig) {
      WMS_config[key] = props.layerConfig[key];
    }

    let heatmapLayer = L.tileLayer.wms(props.url, WMS_config).addTo(container);

    //container.addLayer(heatmapLayer);
    return () => {
      container.removeLayer(heatmapLayer);
    };
  });

  return null;
};

export default HeatmapLayer;
