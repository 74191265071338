import L from "leaflet";
import "./marker.css";
export default function CustomMarker(color, city) {
  let city_no_spaces = city.replace(/\s/g, "-");
  let html_string = `
  <style>
  .popup-bubble-anchor-${city_no_spaces} {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
  }

  .popup-bubble-anchor-${city_no_spaces}::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      /* Center the tip horizontally. */
      transform: translate(-50%, 0);
      /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
      width: 0;
      height: 0;
      /* The tip is 8px high, and 12px wide. */
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid ${color};
  }
  </style>

  <div class="popup-container">
    <div class="popup-bubble-anchor-${city_no_spaces}">
      <div class="popup-bubble" style="background-color:${color}">
        <b>${city}</b>
      </div>
    </div>
  </div>
  `;

  const icon = L.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: html_string,
  });

  return icon;
}
