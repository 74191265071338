import L from "leaflet";
import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";
function CustomCircleMarker(props) {
  const context = useLeafletContext();
  useEffect(() => {
    const marker = new L.CircleMarker(props.center, {
      radius: props.radius,
      color: props.color,
      fillOpacity: 1,
      fill: true,
      pane: props.pane,
    }).bindPopup(props.popupText);

    marker.on("mouseover", function (e) {
      marker.openPopup();
    });
    marker.on("mouseout", function (e) {
      marker.closePopup();
    });

    const container = context.layerContainer || context.map;
    marker.addTo(container);

    return () => {
      container.removeLayer(marker);
    };
  });
  return null;
}

export default CustomCircleMarker;
