import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

class LinePlot extends React.Component {
  
  data = {};

  moving_average = (data, indices) => {
    let moving_average = [];
    let sum_values = [];
    let indices_j = 0;
    for (let i = 0; i < data.length; i++) {
      if(i < indices[indices_j]+1){
        sum_values.push(data[i]);
      }
      else{
        moving_average.push(sum_values.reduce((a, b) => a + b, 0) / sum_values.length);
        sum_values = [];
        indices_j += 1;
      }
    }

    moving_average.push(sum_values.reduce((a, b) => a + b, 0) / sum_values.length);
    return moving_average;
  };     

  indexArray(array, indices){
    let index_array = [];
    for (let i = 0; i < indices.length; i++) {
      index_array.push(array[indices[i]]);
    }
    return index_array;
  }

  render() {
    let points = [];
    if(this.props.moving_average)
      points = this.props.dataToPlot.map((data,idx) => (this.moving_average(data, this.props.indicesSelectedPoints[idx])))
    else
      points = this.props.dataToPlot.map((data,idx) => (this.indexArray(data, this.props.indicesSelectedPoints[idx]))) 

    const options = {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
          },
          ticks: {
            autoSkip: true,
          },
        },
        y:{
          type: "linear",
          ticks: {
            autoSkip: true,
          },
        }
      },
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: this.props.title,
        },
      },
    };

    const data = {
      datasets: points.map((data, idx) => ({
        label: this.props.names[idx],
        data:data.map((p,i) => (
          {
          x:this.props.timestamps[idx][this.props.indicesSelectedPoints[idx][i]],
          y:p,
          })
        ),
        borderColor: this.props.routes_colors[idx],
      })),
    };

    return <Line height="250px" options={options} data={data} />;
  }
}
export default LinePlot;
